@import "assets/stylesheets/custom-theme";

.empty-state {
	margin: 150px auto;

	.btn {
		padding: $size-1 $size-10;
	}
}

.empty-state-image {
	margin-bottom: $size-3;
}

.blur,
.blured-table {
	position: relative;
	text-shadow: 0 0 8px $flicorn-black;
	color: $flicorn-gray-dark-2;

	.card-content {
		display: inline-block;
		padding: $size-11 $size-13;
		background-color: $flicorn-white;
		box-shadow: 0 6px 15px #0000002b;
		white-space: break-spaces;
	}

	img {
		filter: blur(5px);
	}

	.empty-state {
		position: absolute;
		right: 0;
		top: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		background-color: #ffffffd1;
		display: flex;
		align-items: center;
		justify-content: center;
		text-shadow: none;

		.h6 {
			color: $flicorn-primary-black;
		}

		img {
			filter: none;
		}

		.hint {
			margin-bottom: $size-5;
		}
	}

	& ~ tr {
		text-shadow: 0 0 8px $flicorn-black;
		color: $flicorn-gray-dark-2;

		img {
			filter: blur(5px);
		}
	}

	.sm-card {
		.card-content {
			display: flex;
			align-items: center;
			padding: 11px $size-12;

			img {
				height: 30px;
				margin-left: $size-5;
				margin-bottom: 0;
			}

			.h6 {
				margin-left: 100px;
				margin-bottom: 0;
			}
		}
	}
}

.blured-table {
	background-image: url("../../../assets/images/blured-table-bg.png");
	height: 400px;
	background-position: top right;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0;
}
