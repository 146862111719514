@import "assets/stylesheets/custom-theme";
@import "assets/stylesheets/utilities/mixins";

.btn {
	&.btn-primary,
	&.btn-outline-primary {
		&[disabled] {
			background: $flicorn-gray-med-2;
			border-color: $flicorn-gray-med-2;
			color: $flicorn-gray-med-1;
		}
	}

	&.btn-outline-white {
		background: $flicorn-white;
		border: 1px solid $flicorn-gray-med-2;
		color: $flicorn-gray-dark-1;

		&:hover {
			background: $flicorn-gray-light-2;
		}

		&[disabled] {
			background: $flicorn-gray-med-2;
		}
	}

	&.btn-outline-primary {
		&.pinky {
			&:hover {
				color: $flicorn-primary;
				background-color: $flicorn-secondary-pink;
			}
		}
	}

	&.btn-link {
		padding: $size-1 $size-3;

		&.btn-lg {
			padding: $size-7 $size-15;
		}

		&:hover {
			background-color: $flicorn-gray-light-2;
		}

		&[disabled] {
			color: $flicorn-gray-light-1;
		}
	}

	&.muted {
		background-color: $flicorn-white;
		border-color: $flicorn-gray-med-2;
		color: $flicorn-gray-dark-1;

		@include coloring-svg ($flicorn-gray-dark-1);

		&:hover {
			background-color: $flicorn-gray-light-2 !important;
			color: $flicorn-gray-dark-1 !important;
		}
	}

	&.mini {
		width: 40px;
		height: 40px;
		line-height: 0px;
		padding: 0;

		&.more {
			&::after {
				content: "";
				width: 16px;
				height: 16px;
				border: none;
				background-color: $flicorn-gray-dark-1;
				mask: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'%3E%3C/path%3E%3C/svg%3E");
			}

			+ .dropdown-menu.show {
				padding: 0;
				border: 0;
				box-shadow: 0 5px 30px #0000000f;

				html[dir="rtl"] & {
					text-align: right;
				}

				.dropdown-item {
					font-size: $size-4;
					color: $flicorn-gray-dark-1;
					padding: $size-6;
				}
			}
		}

		.icon {
			font-size: $size-11;
		}
	}
}
