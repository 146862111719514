@import "assets/stylesheets/utilities/mixins";
@import "assets/stylesheets/custom-theme";

// Helper classes

.typography-1,
%typography-1 {
	@include typography($font-type2-regular, $size-8, $flicorn-gray-dark-1);
}

.typography-2,
%typography-2 {
	@include typography($font-type2-light, $size-6, $flicorn-black);
}

.typography-3,
%typography-3 {
	@include typography($font-type2-regular, $size-6, $flicorn-primary-black);
}

.typography-4,
%typography-4 {
	@include typography($font-type1-regular, $size-8, $flicorn-gray-dark-1);
}

/* typography classes */

.font-tajawal-light {
	font-family: $font-type2-light;
}

.font-tajawal-regular {
	font-family: $font-type2-regular;
}

.font-tajawal-medium {
	font-family: $font-type2-medium;
}

.font-tajawal-bold {
	font-family: $font-type2-bold;
}

.font-cairo-regular {
	font-family: $font-type1-regular;
}

.font-cairo-bold {
	font-family: $font-type1-bold;
}

.font-cairo-semi-bold {
	font-family: $font-type1-semibold;
}

%size-3,
.size-3 {
	font-size: $size-3;
}

%size-4,
.size-4 {
	font-size: $size-4;
}

%size-5,
.size-5 {
	font-size: $size-5;
}

%size-6,
.size-6 {
	font-size: $size-6;
}
%size-24,
.size-24 {
	font-size: $size-24;
}

%size-13,
.size-13 {
	font-size: $size-13 !important;
}

%size-16,
.size-16 {
	font-size: $size-16 !important;
}

.h1,
h1 {
	font-family: $font-type1-bold;
}

.min-height {
	min-height: 75vh;

	&.mh-80 {
		min-height: 82vh;
	}
}

/* width classes */

.w-95 {
	width: 95%;
}

.w-40 {
	width: 40%;
}

/*
	Some slients classes
*/
%text-start,
.text-start {
	text-align: start;
}
%text-end,
.text-end {
	text-align: end;
}
%form-control {
	display: block;
	width: 100%;
	padding: $size-2 $size-3;
	font-family: $font-type2-regular;
	font-size: $size-8;
	font-weight: $font-weight-normal;
	line-height: 1.5;
	color: $flicorn-primary-black;
	background-color: $flicorn-gray-light-2;
	background-clip: padding-box;
	border: 1px solid $flicorn-gray-med-2;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
%ltr,
.ltr {
	direction: ltr;
}

.btn-inverse {
	&.btn-primary {
		background-color: $flicorn-white;

		&,
		&:hover {
			color: $flicorn-primary;
		}

		&:hover {
			background-color: $flicorn-secondary-pink;
		}
	}
}

.outline-gray {
	@include typography($font-type1-bold, $size-4, $flicorn-gray-dark-1);

	border: 1px solid $flicorn-gray-med-2;
	padding: 8px 18px;
	border-radius: 30px;

	&:hover {
		background-color: $flicorn-gray-light-2;
	}
}

.hint {
	@include typography($font-type2-regular, $size-6, $flicorn-gray-med-1);
}

.z-index-5 {
	z-index: 5;
}
