@import "assets/stylesheets/config/utilities";
@import "assets/stylesheets/utilities/mixins";

// Helper text
.input-helper-text,
.form-label {
	@include typography($font-type2-medium, $size-6, $flicorn-primary-black);
}

// Helper text below the inputs
.input-helper-text {
	color: $flicorn-gray-med-1;
}

// Input container for each input in the form
.input-container {
	margin-bottom: 1.125rem;
}

// Select for RTL

.form-check {
	cursor: pointer;

	&.form-check-inline {
		html[dir="rtl"] & {
			margin-right: 0 !important;
			margin-left: 1rem !important;
		}
	}

	html[dir="rtl"] & {
		padding-left: initial;
		padding-right: 1.5em;

		.form-check-input {
			float: right;
			margin-right: -1.5em;
			margin-left: initial;
		}
	}
}

// Custom dropdpown - FC => (F)li(C)orn

.fc-dropdown {
	&,
	&.show {
		.btn-primary.dropdown-toggle {
			color: $flicorn-gray-med-1;
			background-color: transparent;
			border-color: transparent;
			font-size: $size-4;

			&:focus {
				box-shadow: none;
			}

			&:hover {
				background-color: $flicorn-gray-light-2;
			}
		}
	}

	.dropdown-item {
		font-family: $font-type2-regular;

		@extend %text-start;
	}

	.dropdown-menu[data-bs-popper] {
		html[dir="rtl"] & {
			padding: 0;
		}
	}
}

// has error
.has-error {
	font-family: $font-type1-regular;
	font-size: $size-5;
	color: $flicorn-danger;

	.input-helper-text {
		color: $flicorn-danger;
	}

	.form-control {
		background-color: $flicorn-gray-light-2;

		&,
		&::placeholder,
		.input-helper-text {
			color: $flicorn-danger;
		}

		&:focus {
			border-color: $flicorn-gray-med-2;
		}
	}
}

.form-control {
	border-radius: 0.25rem !important;

	&.is-invalid {
		background-color: $flicorn-secondary-pink;
		color: $flicorn-danger;
	}
}

// Padding direction for select in RTL
.form-select {
	html[dir="rtl"] & {
		padding: 0.563rem 0.625rem 0.563rem 1.875rem;
	}
}

.otp-input {
	width: 65px;

	input {
		@extend %form-control;

		width: 100% !important;
		height: 90px;
		padding-top: $size-13;
		font-size: 50px;
		font-family: $font-type2-medium;

		&:focus {
			outline: none;
			box-shadow: 0 0 4px $flicorn-info;
		}
	}
}

.api-errors {
	list-style: none;
}

.accpet-hint-text {
	@include typography($font-type1-regular, $size-5, $flicorn-gray-dark-2);
}

.app-select2 {
	.social-platform {
		.react-select {
			&__option {
				&--is-selected {
					&::after {
						top: 13px;
					}
				}
			}
		}
	}

	.react-select {
		&__indicator-separator {
			display: none;
		}

		&__control {
			background-color: $flicorn-gray-light-2;
			min-height: $input-height;

			&:hover {
				background-color: $flicorn-white;
				cursor: pointer;
			}

			&,
			&:hover,
			&:focus {
				border-color: $flicorn-gray-med-2;
				box-shadow: 0 0 $flicorn-gray-med-2;
			}
		}

		&__value-container,
		&__option {
			html[dir="rtl"] & {
				@extend %ltr;
				@extend %text-end;

				img {
					float: right;
					margin-left: $size-3;
					position: relative;
					top: 2px;
				}
			}
		}

		&__option {
			color: $flicorn-gray-dark-1;
			border-bottom: 1px solid $flicorn-gray-med-2;
			padding: $size-5;

			&,
			&:hover {
				background: $flicorn-white;
			}

			&--is-selected {
				color: $flicorn-primary-black;
				position: relative;

				&::after {
					content: "";
					position: absolute;
					left: $size-9;
					background-color: $flicorn-success;
					mask: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E") no-repeat 50% 120%;
					mask: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E") no-repeat 50% 80%;
					width: $size-11;
					height: $size-11;
				}
			}

			&:hover {
				color: $flicorn-danger;
				font-weight: bold;
			}
		}
	}

	.country-code {
		.react-select {
			&__option {
				&--is-selected {
					&::after {
						display: none;
					}
				}
			}
		}
	}

	&.rounded-bg {
		.form-label {
			color: $flicorn-gray-dark-2 !important;
			font-size: $size-4 !important;
			margin-bottom: 6px;
		}

		&.state {
			.react-select {
				&__control {
					box-shadow: none;
					border-color: transparent;
				}

				&__placeholder,
				&__single-value {
					font-family: $font-type2-bold;
					font-size: $size-6;
				}

				&__indicator {
					@include coloring-svg($flicorn-gray-dark-1);
				}
			}
		}

		&.state.pending {
			.react-select {
				&__control {
					background: #fcebd0;
				}

				&__placeholder,
				&__single-value {
					color: #f39b12;
				}
			}
		}

		&.state.undefined {
			.react-select {
				&__control {
					border: 1px solid $flicorn-gray-light-1;
					background: $flicorn-white;
				}

				&__placeholder,
				&__single-value {
					color: $flicorn-gray-dark-1;
				}
			}
		}

		&.state.canclled {
			.react-select {
				&__control {
					background: #f9e6e6;
				}

				&__placeholder,
				&__single-value {
					color: $flicorn-danger;
				}
			}
		}

		&.state.info {
			.react-select {
				&__control {
					background: #e5f2fc;
				}

				&__placeholder,
				&__single-value {
					color: $flicorn-info;
				}
			}
		}

		&.state.accepted {
			.react-select {
				&__control {
					background: #dafaf0;
				}

				&__placeholder,
				&__single-value {
					color: $flicorn-success;
				}
			}
		}

		&.state.completed {
			.react-select {
				&__control {
					background: #e4e7ec;
				}

				&__placeholder,
				&__single-value {
					color: $flicorn-gray-dark-1;
				}
			}
		}

		&.state.expired {
			.react-select {
				&__control {
					background: $flicorn-gray-dark-2;
				}

				&__placeholder,
				&__single-value {
					color: $flicorn-white;
				}
			}
		}

		.react-select {
			&__option--is-disabled {
				opacity: .5;
				pointer-events: none;
			}

			&__control {
				background-color: $flicorn-gray-dark-2;
				border-radius: $size-7;
				min-height: $size-15;
			}

			&__menu-list {
				@include modern-scrollbar;
			}

			&__placeholder,
			&__single-value {
				color: $flicorn-white;
				font-family: $font-type1-bold;
				font-size: $size-4;
			}

			&__indicator {
				@include coloring-svg($flicorn-white);
			}

			&,
			&:hover {
				background: $flicorn-gray-dark-2;
			}
		}
	}
}

.date-picker {
	position: relative;

	.form-control {
		html[dir="ltr"] & {
			padding-right: $size-18;
		}
	}

	.icon {
		position: absolute;
		top: 30%;

		path {
			color: $flicorn-gray-dark-1;
		}

		html[dir="rtl"] & {
			left: $size-1;
		}

		html[dir="ltr"] & {
			left: $size-1;
		}
	}
}

// checkbox

.checkbox {
	&:hover {
		cursor: pointer;
	}

	.form-check-input {
		width: 1.3rem;
		height: 1.3rem;
		background-size: 70%;
	}
}

.task-status-form {
	>.mb-3:first-of-type {
		display: none;
	}
}
