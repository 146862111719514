@import "assets/stylesheets/config/utilities";
@import "assets/stylesheets/custom-theme";
@import "~bootstrap/scss/bootstrap";

.loader {
	border: 6px solid $flicorn-secondary-pink;
	border-radius: 50%;
	border-top: 6px solid $flicorn-primary;
	width: 30px;
	height: 30px;
	margin-top: $size-11;
	animation: spin 2s linear infinite;
	transform: translate(-50%, -50%);
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
