@import "assets/stylesheets/custom-theme";

.expired-trial {
	margin: 0 0 $size-11;

	.empty-state-image {
		height: auto;
	}

	.h6 {
		font-size: $size-9;
	}
}

.f-vh {
	height: calc(100vh - 228px);
}
