@import "assets/stylesheets/config/utilities";

.copyrights {
	@include typography($font-type2-medium, $size-5, $flicorn-white);
}

.quick-links {
	.link {
		@include typography($font-type1-bold, $size-4, $flicorn-white);

		&:not(:last-child) {
			html[dir="rtl"] & {
				margin-left: 20px;
			}
		}

		&:hover {
			text-decoration: underline;
		}
	}
}
