$direction: rtl;
$right: right;
$left: left;

body {
	html[dir="rtl"] & {
		direction: rtl;
		text-align: right;

		.text-end {
			text-align: left !important;
		}

		.text-start {
			text-align: right !important;
		}

		@each $breakpoint in map-keys($grid-breakpoints) {
			@include media-breakpoint-up($breakpoint) {
				$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

				@each $size, $length in $spacers {
					.pe#{$infix}-#{$size} {
						padding-#{$left}: $length !important;
						padding-#{$right}: 0 !important;
					}

					.ps#{$infix}-#{$size} {
						padding-#{$right}: $length !important;
						padding-#{$left}: 0 !important;
					}

					.me#{$infix}-#{$size} {
						margin-#{$left}: $length !important;
						margin-#{$right}: 0 !important;
					}

					.ms#{$infix}-#{$size} {
						margin-#{$right}: $length !important;
						margin-#{$left}: 0 !important;
					}

					.ms#{$infix}-n#{$size} {
						margin-#{$right}: -$length !important;
						margin-#{$left}: 0 !important;
					}

					.me#{$infix}-n#{$size} {
						margin-#{$left}: -$length !important;
						margin-#{$right}: 0 !important;
					}
				}

				// RTL TEXT
				.text#{$infix}-end {
					text-align: left !important;
				}

				.text#{$infix}-start {
					text-align: right !important;
				}

				.float#{$infix}-none {
					float: none !important;
				}

				.ms#{$infix}-auto {
					margin-#{$right}: auto !important;
					margin-#{$left}: inherit !important;
				}

				.me#{$infix}-auto {
					margin-#{$left}: auto !important;
					margin-#{$right}: inherit !important;
				}

				.border-end#{$infix} {
					border-right: 0 !important;
					border-left: $border-width solid $gray-300 !important;
				}

				.border-start#{$infix} {
					border-left: 0 !important;
					border-right: $border-width solid $gray-300 !important;
				}

				.border-end#{$infix}-0 {
					border-left: 0 !important;
				}

				.border-start#{$infix}-0 {
					border-right: 0 !important;
				}

				.dropdown-menu[data-bs-popper] {
					left: 0;
					right: auto;
				}

				.dropdown-menu#{$infix}-start {
					--bs-position: start;

					&[data-bs-popper] {
						left: auto !important;
						right: 0 !important;
					}
				}

				.dropdown-menu#{$infix}-end {
					--bs-position: end;

					&[data-bs-popper] {
						left: 0 !important;
						right: auto !important;
					}
				}
			}
		}

		@each $size, $length in $position-values {
			.end-#{$size} {
				#{$left}: $length !important;
				right: auto !important;
			}

			.start-#{$size} {
				#{$right}: $length !important;
				left: auto !important;
			}
		}

		.breadcrumb-item {
			// The separator between breadcrumbs (by default, a forward-slash: "/")
			font-size: $size-4;
			font-family: $font-type1-bold;

			+ .breadcrumb-item {
				padding-left: 0;
				padding-right: $breadcrumb-item-padding-x;

				&::before {
					float: right; // Suppress inline spacings and underlining of the separator
					padding-left: $breadcrumb-item-padding-x;
					padding-right: 0;
				}
			}
		}
	}
}
