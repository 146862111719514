@mixin typography($font-family, $font-size, $color) {
	font-family: $font-family !important;
	font-size: $font-size !important;
	color: $color !important;
}

// Transition

@mixin transition($args...) {
	transition: $args;
}

// Card

@mixin card($card-bg, $card-corners, $card-shadow) {
	background-color: $card-bg;
	border-radius: $card-corners;
	box-shadow: $card-shadow;
}

// background icon and change its color

@mixin bg-icon($bg-icon, $color-icon, $width-icon, $height-icon, $radius-icon) {
	width: $width-icon;
	height: $height-icon;
	background: $bg-icon;
	border-radius: $radius-icon;

	path {
		fill: $color-icon;
	}
}

// color svg icon

@mixin coloring-svg($color-icon) {
	path {
		color: $color-icon;
	}
}

// modern scorll bar
/* stylelint-disable */
@mixin modern-scrollbar {
  --scrollbarBG: #fff;
  --thumbBG: #b7b7b7;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 11px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}
/* stylelint-enable */