@import "assets/stylesheets/config/fonts";

/* Brand colors */
$flicorn-primary: #ec1c24 !default;
$flicorn-primary-black: #333 !default;
$flicorn-secondary-dark-red: #cd070e !default;
$flicorn-secondary-pink: #fde7e8 !default;
$flicorn-secondary-blue: #e5eeff !default;

/* Alert colors */
$flicorn-success: #74c686 !default;
$flicorn-danger: #f55 !default;
$flicorn-info: #52a8ec !default;
$flicorn-warning: #ffd301 !default;

/* Grayscale */
$flicorn-white: #fff !default;
$flicorn-gray-light-1: #eee !default;
$flicorn-gray-light-2: #f4f8fa !default;
$flicorn-gray-med-1: #c6cbd4 !default;
$flicorn-gray-med-2: #e3eaf6 !default;
$flicorn-gray-dark-1: #858597 !default;
$flicorn-gray-dark-2: #a6b7c9 !default;
$flicorn-black: #000 !default;

/* Override bootstrap variables */
$theme-colors: (
	primary: $flicorn-primary,
	primary-black: $flicorn-primary-black,
	secondary-dark-red: $flicorn-secondary-dark-red,
	secondary-pink: $flicorn-secondary-pink,
	secondary-blue: $flicorn-secondary-blue,
	info: $flicorn-info,
	success: $flicorn-success,
	danger: $flicorn-danger,
	warning: $flicorn-warning,
	dark: $flicorn-gray-dark-1,
	dark2: $flicorn-gray-dark-2,
	med-gray: $flicorn-gray-med-1,
	med-gray2: $flicorn-gray-med-2,
	light: $flicorn-gray-light-1,
	light2: $flicorn-gray-light-2,
	white: $flicorn-white,
	black: $flicorn-black,
) !default;
$min-contrast-ratio: 3;
$body-bg: $flicorn-gray-light-2 !default;
$body-color: $flicorn-primary-black !default;

/* Fonts */

/* type 1 */
$font-type1-regular: cairo-regular, sans-serif;
$font-type1-semibold: cairo-semibold, sans-serif;
$font-type1-bold: cairo-bold, sans-serif;

/* type 2 */
$font-type2-light: tajawal-light, sans-serif;
$font-type2-regular: tajawal-regular, sans-serif;
$font-type2-medium: tajawal-medium, sans-serif;
$font-type2-bold: tajawal-bold, sans-serif;

// Sizes

$size-1: 8px;
$size-2: 9px;
$size-3: 10px;
$size-4: 12px;
$size-5: 13px;
$size-6: 14px;
$size-7: 15px;
$size-8: 16px;
$size-9: 17px;
$size-10: 18px;
$size-11: 20px;
$size-12: 22px;
$size-13: 26px;
$size-14: 28px;
$size-15: 30px;
$size-16: 32px;
$size-17: 34px;
$size-18: 35px;
$size-19: 40px;
$size-20: 50px;
$size-24: 24px;

// body styles
$font-family-base: $font-type2-medium;

// Headers font sizes
$h1-font-size: $size-17 !default;
$h2-font-size: $size-13 !default;
$h3-font-size: $size-12 !default;
$h4-font-size: $size-11 !default;
$h5-font-size: $size-9 !default;
$h6-font-size: $size-6 !default;

// font styles
// $font-size-base: $size-6 !default; // 14px
$input-btn-border-radius: $size-15 !default; // 30px
$input-btn-font-family: $font-type1-bold !default;
$headings-font-family: $font-type1-semibold;
$headings-line-height: 1.5 !default;

// buttons border radius
$btn-border-radius: $input-btn-border-radius !default; // 30px
$btn-border-radius-sm: $input-btn-border-radius !default; // 30px
$btn-border-radius-lg: $input-btn-border-radius !default; // 30px

$input-btn-padding-y: $size-4 !default; // 12px
$input-btn-padding-x: $size-14 !default; // 28px
$input-btn-font-size: $size-6;
$input-btn-padding-y-sm: $size-1 !default; // 8px
$input-btn-padding-x-sm: $size-10 !default; // 18px
$input-btn-font-size-sm: $size-4 !default; // 14px

$input-btn-padding-y-lg: $size-7 !default; // 15px
$input-btn-padding-x-lg: $size-18 !default; // 35px
$input-btn-font-size-lg: $size-8 !default; // 16px

// link style
$link-color: $flicorn-primary !default;
$link-decoration: none !default;

// font weight
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

// inputs
$input-bg: $flicorn-gray-light-2;
$input-border-color: $flicorn-gray-med-2;
$input-font-family: $font-type2-regular !default;
$input-padding-y: $size-2 !default; // 9px
$input-padding-x: $size-3 !default; // 10px
$form-label-font-size: $size-5; // 13px
$form-label-font-weight: $font-weight-bold;
$input-font-size: $size-8 !default; // 16px
$input-focus-color: $flicorn-info;
$form-label-font-weight: $font-weight-normal;
$input-disabled-bg: $flicorn-gray-light-1;
$input-disabled-border-color: $flicorn-gray-light-1 !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-bg-position: left $form-select-padding-x center !default;
$form-select-indicator: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='caret-down' class='svg-inline--fa fa-caret-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E") !default;
$form-check-input-bg: $flicorn-white !default;
$form-check-input-checked-bg-color: $flicorn-primary;

// Dropdown
$dropdown-item-padding-y: $size-2;
$dropdown-item-padding-x: $size-7;
$dropdown-link-hover-color: $flicorn-primary;
$dropdown-link-active-bg: $flicorn-gray-light-2;
$dropdown-link-active-color: $flicorn-primary;

// Navbar
$navbar-brand-margin-end: 0;

// table
$table-striped-bg: $flicorn-gray-light-2;
$table-border-color: $flicorn-gray-med-2;

// pagination
$pagination-padding-y: $size-4;
$pagination-padding-x: $size-4;
$pagination-active-color: $flicorn-primary;
$pagination-border-color: $flicorn-gray-light-2;
$pagination-active-bg: $flicorn-white;
$pagination-active-border-color: $flicorn-gray-light-2;
$pagination-disabled-border-color: $flicorn-gray-light-2;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
