@font-face {
	font-family: Cairo-Regular;
	src: url("../../fonts/cairo/Cairo-Regular.eot");
	src:
		url("../../fonts/cairo/Cairo-Regular.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/cairo/Cairo-Regular.woff2") format("woff2"),
		url("../../fonts/cairo/Cairo-Regular.woff") format("woff"),
		url("../../fonts/cairo/Cairo-Regular.ttf")  format("truetype"),
		url("../../fonts/cairo/Cairo-Regular.svg#Cairo") format("svg");
}

@font-face {
	font-family: Cairo-SemiBold;
	src: url("../../fonts/cairo/Cairo-SemiBold.eot");
	src:
		url("../../fonts/cairo/Cairo-SemiBold.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/cairo/Cairo-SemiBold.woff2") format("woff2"),
		url("../../fonts/cairo/Cairo-SemiBold.woff") format("woff"),
		url("../../fonts/cairo/Cairo-SemiBold.ttf")  format("truetype"),
		url("../../fonts/cairo/Cairo-SemiBold.svg#Cairo SemiBold") format("svg");
}

@font-face {
	font-family: Cairo-Bold;
	src: url("../../fonts/cairo/Cairo-Bold.eot");
	src:
		url("../../fonts/cairo/Cairo-Bold.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/cairo/Cairo-Bold.woff2") format("woff2"),
		url("../../fonts/cairo/Cairo-Bold.woff") format("woff"),
		url("../../fonts/cairo/Cairo-Bold.ttf")  format("truetype"),
		url("../../fonts/cairo/Cairo-Bold.svg#Cairo") format("svg");
}

@font-face {
	font-family: Tajawal-Light;
	src: url("../../fonts/tajawal/Tajawal-Light.eot");
	src:
		url("../../fonts/tajawal/Tajawal-Light.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/tajawal/Tajawal-Light.woff2") format("woff2"),
		url("../../fonts/tajawal/Tajawal-Light.woff") format("woff"),
		url("../../fonts/tajawal/Tajawal-Light.ttf")  format("truetype"),
		url("../../fonts/tajawal/Tajawal-Light.svg#Tajawal Light") format("svg");
}

@font-face {
	font-family: Tajawal-Regular;
	src: url("../../fonts/tajawal/Tajawal-Regular.eot");
	src:
		url("../../fonts/tajawal/Tajawal-Regular.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/tajawal/Tajawal-Regular.woff2") format("woff2"),
		url("../../fonts/tajawal/Tajawal-Regular.woff") format("woff"),
		url("../../fonts/tajawal/Tajawal-Regular.ttf")  format("truetype"),
		url("../../fonts/tajawal/Tajawal-Regular.svg#Tajawal") format("svg");
}

@font-face {
	font-family: Tajawal-Medium;
	src: url("../../fonts/tajawal/Tajawal-Medium.eot");
	src:
		url("../../fonts/tajawal/Tajawal-Medium.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/tajawal/Tajawal-Medium.woff2") format("woff2"),
		url("../../fonts/tajawal/Tajawal-Medium.woff") format("woff"),
		url("../../fonts/tajawal/Tajawal-Medium.ttf")  format("truetype"),
		url("../../fonts/tajawal/Tajawal-Medium.svg#Tajawal Medium") format("svg");
}

@font-face {
	font-family: Tajawal-Bold;
	src: url("../../fonts/tajawal/Tajawal-Bold.eot");
	src:
		url("../../fonts/tajawal/Tajawal-Bold.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/tajawal/Tajawal-Bold.woff2") format("woff2"),
		url("../../fonts/tajawal/Tajawal-Bold.woff") format("woff"),
		url("../../fonts/tajawal/Tajawal-Bold.ttf")  format("truetype"),
		url("../../fonts/tajawal/Tajawal-Bold.svg#Tajawal") format("svg");
}
