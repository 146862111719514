@import "assets/stylesheets/custom-theme";

.navbar {
	font-family: $font-type1-bold;
	font-size: $size-4;
	color: $flicorn-gray-dark-1;

	&.navbar-light {
		.navbar-links {
			.nav-link {
				color: $flicorn-gray-dark-2;
				padding: $size-3 0;

				html[dir="rtl"] & {
					margin-left: $size-7;
				}

				&:hover,
				&.active {
					color: $flicorn-primary;
				}

				&.active {
					border-bottom: 2px solid $flicorn-primary;
				}
			}
		}
	}

	.brand-logo {
		height: 50px;

		html[dir="rtl"] & {
			margin-left: $size-15;
		}
	}
}

.avatar {
	height: 40px;

	html[dir="rtl"] & {
		margin-left: $size-1;
	}
}
